//Polyfill
import "core-js";

//Can't resolve 'regenerator-runtime/runtime'が表示されたとき(async awaitを使う場合など)
//import  regeneratorRuntime  from  "regenerator-runtime" ;
import jQuery from "jquery";

const $ = jQuery; // ドルマークに参照を代入(慣習的な $ を使うため)

$(document).ready(function () {
  // .header-btnがクリックされたときの処理
  $(".header-btn").click(function () {
    // .header-btnと.header-spのopenクラスをトグル（追加/削除）
    $(".header-btn, .header-sp").toggleClass("open");
  });

  // .header-sp内のaタグがクリックされたときの処理
  $(".header-sp a").click(function () {
    // .header-btnと.header-spからopenクラスを削除
    $(".header-btn, .header-sp").removeClass("open");
  });

  $(".front-faq-box").on("click", function () {
    $(this).toggleClass("open");
  });

  // ウィンドウの幅をチェックして、スマホかどうかを判定
  var isMobile = $(window).width() <= 768;

  // 初期状態の設定
  $(".side-nav-box--title").each(function () {
    if (isMobile) {
      // スマホの場合、タイトルに.openクラスを追加し、リストを非表示にする
      $(this).addClass("open");
      $(this).next(".side-nav-box--list").hide();
    } else {
      // デスクトップの場合、リストを表示したままにする
      $(this).next(".side-nav-box--list").show();
    }
  });

  // タイトルをクリックしたときの処理
  $(".side-nav-box--title").on("click", function () {
    // クリックされたタイトルに.openクラスをトグルする
    $(this).toggleClass("open");
    // 次の要素（リスト）をスライドトグルする
    $(this).next(".side-nav-box--list").stop(true, true).slideToggle();
  });

  function checkWidth() {
    if ($(window).width() >= 768) {
      // PCサイズの場合
      $(".faq-content .front-faq-box").addClass("open");
    } else {
      // SPサイズの場合
      $(".faq-content .front-faq-box").removeClass("open");
    }
  }
  // ページが読み込まれた時と、ウィンドウサイズが変更された時に実行
  checkWidth();
  $(window).resize(checkWidth);

  //TOP voiceスライド
  const swiper = new Swiper(".voice-slide", {
    loop: true,
    spaceBetween: 20,
    speed: 1500,
    autoplay: { // 自動再生
      delay: 1500, // 1.5秒後に次のスライド
    },
    pagination: {
      el: ".voice-swiper-pagination",
    },
  });
  //TOP NEWSスライド
  const swiper = new Swiper(".news-slide", {
    loop: true,
    spaceBetween: 20,
    speed: 1500,
    autoplay: { // 自動再生
      delay: 1500, // 1.5秒後に次のスライド
    },
    pagination: {
      el: ".swiper-pagination",
    },
  });
});
